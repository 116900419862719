<template>
  <v-container fluid>
    <v-row justify="center" v-if="ComConfig">
      <v-dialog v-model="ComConfig" max-width="500">
        <v-card>
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="h6" v-bind="attrs" v-on="on"
                  ><h4>Convocation des membres de la commission d'ouverture</h4></span
                >
              </template>
              <span>Convocations des membres de la commission d'ouverture</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              v-if="this.$store.getters['Auth/getCom']"
              :items="
                this.$store.getters['Auth/getCom'].filter(function(value, index, arr) {
                  return value.type.status == 1;})
              "
              item-text="title"
              item-value="id"
              v-model="com.com"
              outlined
              label="Choisir la commission d’ouverture en fonction de l’objet du dossier concerné"
            ></v-autocomplete>
            <v-file-input
              v-model="com.file"
              multiple
              label="Joindre la décision de convocation"
              truncate-length="15"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="white--text" block @click="comCall()">
              ENVOYER LA CONVOCATION
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" v-if="profile == 3">
        <v-card class="mt-4">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="h6" v-bind="attrs" v-on="on"
                  ><h4>Ajout d'une séance publiques d'ouverture</h4></span
                >
              </template>
              <span
                >Création/ajout d'une séance publique d'ouverture des offres/propositions/plis</span
              >
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <template>
              <v-container fluid>
                <v-radio-group v-model="Dtype" row>
                  <v-radio
                    label="Créer une séance publique d'ouverture des offres/propositions/plis "
                    v-bind:value="false"
                  ></v-radio>
                  <v-radio
                    label="Créer une séance publique d'ouverture des réponses aux MI/APQ (si prévu)"
                    v-bind:value="true"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </template>

            <v-autocomplete
              v-if="!Dtype"
              v-model="session.document"
              v-on:change="getDateDoc()"
              :items="this.$store.getters['Auth/getlistSubmissionFoldNoti'].docs"
              item-text="object"
              item-value="id"
              outlined
              label="Sélectionner le dossier correspondant à la séance publique d'ouverture des offres/propositions/plis "
            ></v-autocomplete>
            <v-autocomplete
              v-if="Dtype"
              v-model="session.notice"
              :items="this.$store.getters['Auth/getlistSubmissionFoldNoti'].notice_ami_apq"
              item-text="object"
              item-value="id"
              v-on:change="getDateNotice()"
              outlined
              label="Sélectionner l’avis relatif à la séance publique d’ouverture des réponses aux Manifestations d’Intérêts/Avis de Pré-qualification (si prévu)"
            ></v-autocomplete>
            <date-picker
              label="Date de la séance publique d'ouverture"
              class="mb-3"
              confirm
              :lang="lang"
              format="dddd, D MMMM YYYY à HH:mm:ss A"
              disabled
              v-model="session.start_time"
              value-type="timestamp"
              type="datetime"
              placeholder="Date de la séance publique d'ouverture"
            ></date-picker>
            <v-text-field
              v-model="session.duration"
              name="input"
              type="number"
              label="Définir la durée en minutes de la séance publique d’ouverture"
            ></v-text-field>
            <v-text-field
              class="mb-1"
              v-model="session.password"
              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
              label="Saisir le mot de passe pour accéder à la séance publique d’ouverture des Offres/Propositions/Plis"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" small @click="addSession" class="white--text" block>
              <v-icon small dark>mdi-server-security</v-icon>PROGRAMMER LA SEANCE PUBLIQUE D’OUVERTURE
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" :md="dynamicCol">
        <v-col>
          <v-card class="mx-auto">
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h4" v-bind="attrs" v-on="on"
                    ><h4>Liste détaillée des séances publiques d’ouvertures</h4></span
                  >
                </template>
                <span>Liste détaillée des séances publiques d’ouvertures</span>
              </v-tooltip>
              <v-spacer></v-spacer
              ><v-chip class="ma-2">{{
                this.$store.getters["Auth/get_list_session"].length
              }}</v-chip>
            </v-card-title>

            <v-divider class="mt-2"></v-divider>
            <v-virtual-scroll
              :items="this.$store.getters['Auth/get_list_session']"
              :item-height="50"
              height="410"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon large>mdi-server-security</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content v-if="item.document">
                    <v-list-item-title>{{ item.document.object }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.sessionDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-content v-if="item.notice">
                    <v-list-item-title>{{ item.notice.object }}</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ item.sessionDate | moment("dddd, Do MMMM YYYY à HH:mm:ss A") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn v-if="item.firstCommission" @click="seemeeting(item)" depressed small>
                      <span> </span>
                      <v-spacer></v-spacer>
                      <span>Acceder</span>
                      <v-chip outlined label class="mx-1" v-if="item.sessionFin">SPF</v-chip>
                      <v-chip label outlined class="mx-1" v-if="item.sessionTech">SPT</v-chip>
                      <v-icon color="grey" right>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="!item.firstCommission && profile == 3"
                      color="red"
                      @click="setComConfig(item)"
                      outlined
                      small
                    >
                      <span> </span>
                      <v-spacer></v-spacer>
                      <span>CONVOQUER LES MEMBRES DE LA COMMISSION</span>
                      <v-chip outlined label class="mx-1" v-if="item.sessionFin">SPF</v-chip>
                      <v-chip label outlined class="mx-1" v-if="item.sessionTech">SPT</v-chip>
                      <v-icon color="red" right>mdi-briefcase</v-icon>
                    </v-btn>
                    <v-btn disabled v-if="!item.firstCommission && profile != 3" depressed small>
                      <span> </span>
                      <v-spacer></v-spacer>
                      <span>Convocation ...</span>
                      <v-chip outlined label class="mx-1" v-if="item.sessionFin">SPF</v-chip>
                      <v-chip label outlined class="mx-1" v-if="item.sessionTech">SPT</v-chip>
                      <v-icon color="red" right>mdi-circle</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";

export default {
  components: {
    DatePicker
  },
  data: () => ({
    com: {
      file: [],
      com: 0
    },
    session: {
      objet: "",
      location: "",
      start_time: null,
      duration: "",
      password: "",
      document: "",
      notice: ""
    },
    lang: {
      // the locale of formatting and parsing function
      formatLocale: {
        // MMMM
        months: [
          "Janvie",
          "Fevrier",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Decembre"
        ],
        // MMM
        monthsShort: [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ],
        // dddd
        weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
        // ddd
        weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        // dd
        weekdaysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        // first day of week
        firstDayOfWeek: 0,
        // first week contains January 1st.
        firstWeekContainsDate: 1,
        // format 'a', 'A'
        meridiem: (h, _, isLowercase) => {
          const word = h < 12 ? "AM" : "PM";
          return isLowercase ? word.toLocaleLowerCase() : word;
        },
        // parse ampm
        meridiemParse: /[ap]\.?m?\.?/i,
        // parse ampm
        isPM: input => {
          return `${input}`.toLowerCase().charAt(0) === "p";
        }
      },
      // the calendar header, default formatLocale.weekdaysMin
      days: [],
      // the calendar months, default formatLocale.monthsShort
      months: [],
      // the calendar title of year
      yearFormat: "YYYY 年",
      // the calendar title of month
      monthFormat: "MMM",
      // the calendar title of month before year
      monthBeforeYear: false
    },

    dynamicCol: 6,
    ComConfig: false,
    Dtype: false,
    sessionIDCom: ""
  }),
  created() {
    this.$store.dispatch("Auth/getSubmissionFoldNoti");
    this.$store.dispatch("Auth/getAllConfigHome");
    this.$store.dispatch("Auth/loadAllCom");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    if (this.profile != 3) {
      this.dynamicCol = 12;
    }
    this.$store.dispatch("Auth/loadAllNotice");
    this.$store.dispatch("Auth/loadAllDoc");
    this.$store.dispatch("Auth/loadAllSession");
  },
  watch: {
    Dtype: {
      handler: "clearData"
    }
  },
  methods: {
    ...mapActions({
      extension_addN: "Auth/extension_addN"
    }),
    async getDateDoc() {
      if (this.session.document) {
        let doc = [];
        await this.$store.dispatch("Auth/getDoc", this.session.document);
        doc = this.$store.getters["Auth/get_selected_doc"].document;
        //console.log(doc);
        if (doc.date_opening) {
          this.session.start_time = doc.date_opening;
        } else {
          this.session.start_time = null;
          Swal.fire({
            title: "Attention !",
            text: `La date d'ouverture des offres n'est pas configuree  pour le dossier de concurrence  ${doc.object}`,
            icon: "error"
          });
        }
      }
    },
    clearData() {
      this.session.object = "";
      this.session.location = "";
      this.session.start_time = null;
      this.session.duration = "";
      this.session.password = "";
      this.session.document = "";
      this.session.notice = "";
    },
    async getDateNotice() {
      if (this.session.notice) {
        let notice = [];
        await this.$store.dispatch("Auth/getNotice", this.session.notice);
        notice = this.$store.getters["Auth/get_selected_notice"].notice;
        //console.log(notice);
        if (notice.deadline) {
          this.session.start_time = notice.deadline;
        } else {
          this.session.start_time = null;
          Swal.fire({
            title: "Attention !",
            text: `La date d'ouverture des offres n'est pas configuree  pour l'avis ${notice.object}`,
            icon: "error"
          });
        }
      }
    },
    addSession() {
      const data = new FormData();
      if (this.Dtype) {
        data.append("notice", this.session.notice);
      } else {
        data.append("document", this.session.document);
      }
      data.append("start_time", moment(this.session.start_time).format("YYYY-MM-DD H:m:s"));
      data.append("duration", this.session.duration);
      data.append("password", this.session.password);
      this.$store.dispatch("Auth/addSession", data);
      this.clearData();
    },
    setComConfig(item) {
      this.ComConfig = !this.ComConfig;
      this.sessionIDCom = item.id;
    },
    comCall() {
      const data = new FormData();
      data.append("commission", this.com.com);
      data.append("session", this.sessionIDCom);
      data.append("file", this.com.file[0]);
      this.$store.dispatch("Auth/comCall", data);

      this.ComConfig = !this.ComConfig;
    },
    seemeeting(item) {
      //console.log(item);
      if (new Date() <= new Date(item.sessionDate)) {
        Swal.fire({
          title: "Attention !",
          text: `Impossible d’accéder à la séance publique d’ouverture avant la date et l’heure prévue pour le ${moment(item.sessionDate).format(
            "dddd, Do MMMM YYYY à HH:mm:ss A"
          )}`,
          icon: "error"
        });
      } else {
        this.$router.push({ name: "showSession", params: { id: parseInt(item.id) } });
      }
    }
  }
};
</script>
<style>
.vue-daterange-picker {
  width: 100% !important;
}
.mx-datepicker {
  width: 100% !important;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>

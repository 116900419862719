<template>
  <div v-show="sessionId">
    <div class="d-flex justify-between">
      <v-btn v-show="content" color="primary" @click.prevent="addPvSession(content)">
        ENVOYER LE PROCES-VERBAL D’OUVERTURE ET LES LISTES DE PRESENCE POUR SIGNATURE
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-show="!synchro && content" color="secondary" @click.prevent="backupContent(content)">
        SAUVEGARDER LE DOCUMENT
      </v-btn>
      <span class="" v-show="synchro">
        En cours de sauvegarde ...
      </span>
    </div>
    <v-divider></v-divider>
    <v-select
      :items="this.$store.getters['Auth/get_selected_model_pv_by_session']"
      item-text="title"
      v-model="selectedModel"
      item-value="id"
      v-show="hasBackup"
      class="text--primary"
      v-on:change="ModelUsed()"
      label="Sélectionner le modèle de PV d’ouverture pour commencer la séance"
      persistent-hint
      return-object
      single-line
    >
    </v-select>
    <v-divider class="my-2"></v-divider>
    <el-tiptap
      @onUpdate="onUpdate"
      height="100%"
      class="mt-2"
      width="100%"
      v-show="content"
      :extensions="extensions"
      :content="content"
      placeholder="Rédaction du Procès-Verbal d'ouverture des offres/proposition/plis à ce niveau"
    >
    </el-tiptap>
  </div>
</template>

<script>
import {
  // all extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  LineHeight,
  Image,
  TrailingNode,
  Table, // use with TableHeader, TableCell, TableRow
  TableHeader,
  TableCell,
  TableRow,
  TextColor,
  FontSize,
  TextHighlight,
  Preview,
  Print,
  Fullscreen
} from "element-tiptap";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DynamicEditor",
  props: {
    sessionId: {
      type: Number,
      required: true
    },
    sessionType: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    GeneratedSignList: "",
    GeneratedSignList2: "",
    synchro: false,
    selectedModel: "0",
    allSign: [],
    isPvDone: false,
    extensions: [
      new Doc(),
      new Text(),
      new Paragraph(),
      new Heading({ level: 5 }),
      new Bold({ bubble: true }),
      new Underline({ bubble: true }),
      new Italic({ bubble: true }),
      new Strike({ bubble: true }),
      new Image({ menubar: false }),
      new Link({ menubar: false }),
      new TextAlign(),
      new ListItem(),
      new FontSize({
        fontSizes: ["8", "10", "12", "14", "16", "18", "20", "24", "30", "36", "48"]
      }),
      new LineHeight(),
      new TrailingNode(),
      new Table({
        resizable: true,
        bubble: true
      }),
      new TableHeader({ bubble: true }),
      new TableCell({ bubble: true }),
      new TableRow({ bubble: true }),
      new TextColor({ bubble: true }),
      new TextHighlight({ bubble: true }),
      new Preview(),
      new Print({ bubble: true }),
      new BulletList({ bubble: true }),
      new OrderedList({ bubble: true }),
      new TodoItem(),
      new TodoList(),
      new HardBreak(),
      new HorizontalRule({ bubble: true }),
      new Fullscreen({ bubble: true }),
      new History({ bubble: true })
    ],
    content: ``,
    hasBackup: false
  }),
  computed: {
    ...mapGetters({
      get_all_signature: "Auth/get_all_signature",
      get_sync_so: "Auth/get_sync_so"
    })
  },
  watch: {
    hasBackup: function(val, oldVal) {
      this.UpdateIfSave();
      // console.log("new: %s, old: %s", val, oldVal,this.get_sync_so);
    }
  },
  methods: {
    ...mapActions({
      loadAllSignature: "Auth/loadAllSignature"
    }),
    ModelUsed() {
      if (this.selectedModel) {
        this.content = this.selectedModel.fileBlob;
      } else {
      }
    },
    async addPvSession(content) {
      const data = new FormData();
      data.append("file_blob", content);
      data.append("open_session", this.sessionId);
      await this.$store.dispatch("Auth/addPV", data);
    },
    onUpdate(ev) {
      const UpdatedContent = ev;
      // this.synchro = !this.synchro;
      this.content = UpdatedContent;
    },
    async UpdateIfSave() {
      await this.$store.dispatch("Auth/get_sync_soPV", this.sessionId);
      if (this.get_sync_so.status == 0 && this.get_sync_so.data) {
        this.hasBackup = false;
        this.content = this.get_sync_so.data.fileBlob;
      }
    },
    async backupContent(UpdatedContent) {
      this.synchro = !this.synchro;
      const data = new FormData();
      data.append("file_blob", UpdatedContent);
      data.append("session_id", this.sessionId);
      data.append("isDone", 0);
      this.$store.dispatch("Auth/sync_soPV", data).then(() => {
        this.synchro = !this.synchro;
        this.$router.go();
      });
    }
  },
  created() {
    this.$store.dispatch("Auth/get_sync_soPV", this.sessionId);
    this.$store.dispatch("Auth/getModelPvBySession", this.sessionType.status);
    // console.log('Yayy',this.get_sync_so);
    if (this.get_sync_so.status == 0 && this.get_sync_so.data) {
      this.hasBackup = false;
      //  console.log('Yayy',this.get_sync_so.data.fileBlob);
      this.content = this.get_sync_so.data.fileBlob;
    } else {
      this.hasBackup = true;
      // console.log('Nope',this.get_sync_so);
      // this.content = this.content0
    }
  }
  // beforeDestroy() {
  //   this.$root.$el.parentNode.removeChild(this.$root.$el);
  // },
};
</script>
<style lang="scss">
.el-tiptap-editor {
  .el-tiptap-editor__content {
    height: 100vh;
  }
}
/* Basic editor styles */
.ProseMirror {
  margin: 1rem 0;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

/* Table-specific styling */
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>

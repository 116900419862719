<template>
  <v-container fluid>
    <v-row v-show="this.id">
      <v-col cols="12" md="4">
        <v-card class="pa-2">
          <v-card-title>
            <h4>
              <!--Information sur la séance publique d’ouverture des Offres/Propositions/Plis concernés-->
              <!--Information sur la séance publique d’ouverture des Propositions Financières concernées-->
              {{ this.$store.getters["Auth/get_selected_session"].session.sessionType.description }}
            </h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Date/heure de la séance publique d’ouverture :</b>
            {{
              this.$store.getters["Auth/get_selected_session"].session.sessionDate
                | moment("dddd, Do MMMM YYYY")
            }}
            <v-spacer></v-spacer>
            <b>Intitulé  de la commission d’ouverture en rapport avec le dossier concerné :</b>
            {{
              this.$store.getters["Auth/get_selected_session"].session.firstCommission
                ? this.$store.getters["Auth/get_selected_session"].session.firstCommission.title
                : "Non configurée"
            }}
            <v-spacer></v-spacer>
            <b>Lien de la visioconférence pour accéder à la séance publique d’ouverture :</b>
            {{ this.$store.getters["Auth/get_selected_session"].session.link }}
            <v-spacer></v-spacer>
            <span>
              <b>Nombre total des Offres/Propositions/Plis reçus :</b>
              <v-chip class="primary ma-2">
                {{ this.$store.getters["Auth/get_selected_session"].offerList.length }}
              </v-chip>
            </span>
          </v-card-text>
        </v-card>
        <v-card class="mt-4" v-if="profile != 2">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h6" v-bind="attrs" v-on="on">Registre de dépôt  des Offres/Propositions/Plis reçues</h4>
              </template>
              <span>Registre de dépôt  des Offres/Propositions/Plis reçues</span>
            </v-tooltip>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-list-item
              v-for="(value, propertyName, index) in this.$store.getters['Auth/get_offer_keys']
                .codes"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ propertyName }}: {{ value.offer.createdBy.firstname }}
                </v-list-item-title>
                <v-list-item-subtitle> Code d'accées : {{ value.code }} </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Emis le : {{ value.offer.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-card>
        <div v-if="this.$store.getters['Auth/get_selected_session'].session.document">
          <v-card
            v-if="
              profile == 3 && this.$store.getters['Auth/get_selected_session'].session.sessionTech
            "
            class="mt-2"
          >
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on">
                    <h4>
                      Programmation de la séance publique d’ouverture des Propositions financières
                    </h4></span
                  >
                </template>
                <h4>
                  Creation de la seance publique d'ouverture des offres financieres apres
                  evaluations des propositions technique
                </h4>
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <v-select
                :items="this.$store.getters['Auth/get_selected_session'].offerList"
                item-text="createdBy.firstname"
                v-model="fin.selected"
                item-value="id"
                multiple
                label="Sélectionner les consultants retenus suite à l’évaluation des propositions techniques"
                persistent-hint
                single-line
              >
              </v-select>
              <date-picker
                label="Date de l'ouverture"
                v-model="fin.start_time"
                value-type="format"
                format="YYYY-MM-DD H:m:s"
                type="datetime"
                :default-value="new Date()"
                class="mb-3"
                placeholder="Renseigner la date/l’heure de la séance publique d’ouverture des propositions financières"
              ></date-picker>
            </v-card-text>
            <v-card-actions>
              <v-btn small color="primary" class="white--text" block @click="financialPropAdd()">
                PROGRAMMER LA SEANCE D’OUVERTURE DES PROPOSITIONS FINANCIERES
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>

        <v-card
          class="mt-2"
          v-if="profile == 4 && !this.$store.getters['Auth/get_selected_session'].signature_state"
        >
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h4" v-bind="attrs" v-on="on">Signature du Procès-Verbal par les membres de la commission</h4>
              </template>
              <span>Signature du Procès-Verbal par les membres de la commission</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="undo" v-if="!signed" class="white--text" icon>
              <v-icon>
                mdi-backup-restore
              </v-icon>Reprendre la signature
            </v-btn>
          </v-card-title>
          <v-card-text>
            <VueSignaturePad
              class="mx-auto"
              style="border:1px solid red"
              height="120px"
              width="100%"
              :options="{ penColor: 'rgba(0,43,89)', minWidth: 0.2, maxWidth: 1.5 }"
              ref="signaturePad"
            />
            <div></div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="save" v-if="!signed" class="white--text" block>
              <v-icon class="mr-2" dark>mdi-draw</v-icon>VALIDER LA SIGNATURE
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mt-4" v-if="profile != 2">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h6" v-bind="attrs" v-on="on">Liste et nombre des éventuels retraits d’Offres/Propositions/Plis</h4>
              </template>
              <span>Liste et nombre des éventuels retraits d’Offres/Propositions/Plis</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-chip class="primary">
              {{ this.$store.getters["Auth/get_list_removed_offer"].length }}
            </v-chip>
          </v-card-title>
          <v-card-text v-if="docorNoticeID">
            <v-list-item
              v-for="value in this.$store.getters['Auth/get_list_removed_offer']"
              :key="value.id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ value.createdBy.firstname }}
                </v-list-item-title>
                <v-list-item-subtitle> Raison : {{ value.reason }} </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ value.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <span> </span>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="primary" class="white--text" block @click="getDOcorNoticeID">
              <v-icon small class="mr-2" dark>mdi-cloud-upload</v-icon>ACTUALISER LES INFORMATIONS SUR LES RETRAITS
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mt-4" v-if="profile != 2">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h6" v-bind="attrs" v-on="on">Liste et nombre des  participants des soumissionnaires présents à la visioconférence</h4>
              </template>
              <span>Liste et nombre des  participants des soumissionnaires présents à la visioconférence</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-chip class="primary">
              {{ this.$store.getters["Auth/get_selected_session_list_presence"].length }}
            </v-chip>
          </v-card-title>
          <v-card-text
            v-show="this.$store.getters['Auth/get_selected_session_list_presence'].length"
          >
            <v-virtual-scroll
              :items="this.$store.getters['Auth/get_selected_session_list_presence']"
              :item-height="50"
              height="110"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon large>mdi-account-circle</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.participant }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="primary" class="white--text" block @click="getSessionListPresence">
              <v-icon small class="mr-2" dark>mdi-cloud-upload</v-icon>ACTUALISER LA LISTE ET LE NOMBRE DES  PARTICIPANTS
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-4" v-if="profile != 2">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <h4 class="h6" v-bind="attrs" v-on="on">Liste/nombre/qualité des membres de la commission d’ouverture des Offres/Propositions/Plis</h4>
              </template>
              <span>Liste/nombre/qualité des membres de la commission d’ouverture des Offres/Propositions/Plis</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-chip class="primary">
              {{ this.$store.getters["Auth/get_attendance_list"].users_commissions.length }}
            </v-chip>
          </v-card-title>
          <v-card-text>
            <v-virtual-scroll
              :items="this.$store.getters['Auth/get_attendance_list'].users_commissions"
              :item-height="50"
              height="110"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <!-- <v-icon large color="primary" v-show="JSON.parse(localStorage.getItem('userData')).id == item.id">mdi-account-circle</v-icon> -->
                    <v-icon medium>mdi-account-circle</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.user.firstname }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.user.lastname }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <span>{{ item.type.description }}</span>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="profile != 2">
        <v-card>
          <v-card-title>
            <h4>
             <!-- Rédaction du Procès-Verbal (PV) de la séance publique d’ouverture des Offres/Propositions/Plis-->
             <!--Procès-Verbal (PV) de la séance publique d’ouverture des Propositions Financières -->
              {{ this.$store.getters["Auth/get_selected_session"].session.sessionType.description }}
            </h4>
            <v-spacer></v-spacer>
            <span v-show="!this.$store.getters['Auth/get_selected_session'].session.generatedSign">
              <v-btn
                v-show="this.$store.getters['Auth/get_selected_session'].pvs"
                @click.prevent="GenerateSignature()"
                text
                depressed
                class="secondary"
              >
                Valider le pv de negociation
              </v-btn>
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="!this.$store.getters['Auth/get_selected_session'].pvs">
            <DynamicEditor
              :key="this.id"
              :sessionId="this.id"
              :sessionType="this.$store.getters['Auth/get_selected_session'].session.sessionType"
            />
          </v-card-text>
          <v-card-text v-if="this.$store.getters['Auth/get_selected_session'].pvs">
            <EditorViewer
              v-if="profile == 3"
              :isAllowedPrint="true"
              :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_session'].pvs"
            />
            <EditorViewer
              v-if="profile != 3"
              :isAllowedPrint="false"
              :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_session'].pvs"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" v-if="profile == 2">
        <v-card>
          <v-card-title>
            <h4>
              Le Procès-Verbal (PV) de la
              {{ this.$store.getters["Auth/get_selected_session"].session.sessionType.description }}
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="this.$store.getters['Auth/get_selected_session'].pvs">
            <EditorViewer
              :isAllowedPrint="false"
              :key="this.id"
              :isPvObject="this.$store.getters['Auth/get_selected_session'].pvs"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DynamicEditor from "../../../config/DynamicEditor";
import EditorViewer from "../../../config/EditorViewer";
import DatePicker from "vue2-datepicker";
import { mapActions, mapGetters } from "vuex";
import "vue2-datepicker/index.css";

export default {
  components: { EditorViewer, DynamicEditor, DatePicker },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    userData: "",
    fin: {
      start_time: null,
      selected: []
    },
    allSign: [],
    GeneratedSignListCom: "",
    GeneratedSignListSoum: "",
    com: {
      file: [],
      signed: false,
      com: 0
    },
    docorNoticeID: 0,
    pv: [],
    ppm: [],
    additif: false
  }),
  computed: {
    ...mapGetters({
      get_all_signature: "Auth/get_all_signature",
      get_sync_so: "Auth/get_sync_so"
    })
  },
  created() {
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.userData = Virt;
    this.$store.dispatch("Auth/getSession", this.id);
    this.$store.dispatch("Auth/getOfferKeys", this.id);
    this.$store.dispatch("Auth/loadAllPv");
    this.$store.dispatch("Auth/getAttendanceList", this.id);
    this.$store.dispatch("Auth/getSessionListPresence", this.id);
    this.$store.dispatch("Auth/loadAllCom");
    this.$store.dispatch("Auth/getAllPostponement");
  },
  methods: {
    ...mapActions({
      loadAllSignature: "Auth/loadAllSignature"
    }),
    getListRemovedOffer() {
      this.$store.dispatch("Auth/getAllRemovedOffer", this.docorNoticeID);
    },
    async getDOcorNoticeID() {
      if (this.$store.getters["Auth/get_selected_session"].session.document) {
        this.docorNoticeID = this.$store.getters["Auth/get_selected_session"].session.document.id;
        await this.getListRemovedOffer();
      }
      if (this.$store.getters["Auth/get_selected_session"].session.notice) {
        this.docorNoticeID = this.$store.getters["Auth/get_selected_session"].session.notice.id;
        await this.getListRemovedOffer();
      }
    },
    getSessionListPresence() {
      this.$store.dispatch("Auth/getSessionListPresence", this.id);
    },
    undo() {
      this.$refs.signaturePad.clearSignature();
    },
    async UpdatePvSession(contentReceived, signature) {
      const data = new FormData();
      let content = contentReceived;
      content += signature;
      data.append("file_blob", content);
      data.append("open_session", this.id);
      await this.$store.dispatch("Auth/addPV", data);
      this.$router.go();
    },
    async GenerateSignature() {
      await this.loadAllSignature(this.id).then(() => {
        this.allSign = this.get_all_signature.data;
      });
      this.getThem();
    },
    async getThem() {
      let contener = "";
      let session = this.id;
      let collected_signature = this.allSign.filter(function(value, index, arr) {
        return value.openSession.id == session;
      }, session);
      let AllCom = collected_signature.filter(function(value, index, arr) {
        return value.com;
      }, session);
      let AllRep = collected_signature.filter(function(value, index, arr) {
        return value.rep;
      }, session);

      AllCom.forEach((element, index, arr) => {
        this.GeneratedSignListCom += `<tr>
            <td>${index + 1}</td>
            <td>${element.name} ${element.surname}</td>
            <td>${element.fonction}</td>
            <td>${element.quality}</td>
            <td>
              <img width="277" src="${element.fileBlob}" alt="Signature ${element.id}" />
            </td>
          </tr>`;
      });

      AllRep.forEach((element, index, arr) => {
        this.GeneratedSignListSoum += `<tr>
            <td>${index + 1}</td>
            <td>${element.name} ${element.surname}</td>
            <td>${element.fonction}</td>
            <td>${element.quality}</td>
            <td>
              <img width="277" src="${element.fileBlob}" alt="Signature ${element.id}" />
            </td>
          </tr>`;
      });

      let signatureCom = `<p>
      <strong>Annexe 1 : Liste de présence des membres de la commission</strong></p>
      <div class="tableWrapper">
            <table style="min-width: 50px;">
              <tbody>
                <tr>
                  <th><p>N°</p></th>
                  <th><p>Nom et prénoms</p></th>
                  <th><p>Fonction/Structure</p></th>
                  <th><p> Qualité du membre de la commission</p></th>
                  <th><p>Signature</p></th>
                </tr>
                ${this.GeneratedSignListCom}
              </tbody>
            </table>
        </div>
        <p><br class="ProseMirror-trailingBreak"></p>`;

      let signatureRep = `<p>
      <strong>Annexe 2 : Liste de présence des représentants des soumissionnaires</strong></p>
      <div class="tableWrapper">
            <table style="min-width: 50px;">
              <tbody>
                <tr>
                  <th><p>N°</p></th>
                  <th><p>Nom et prénoms</p></th>
                  <th><p>Structure/Cabinet</p></th>
                  <th><p>Qualité du représentant</p></th>
                  <th><p>Signature</p></th>
                </tr>
                ${this.GeneratedSignListSoum}
              </tbody>
            </table>
        </div>
        <p><br class="ProseMirror-trailingBreak"></p>`;

      contener += signatureCom;
      contener += signatureRep;

      this.UpdatePvSession(
        this.$store.getters["Auth/get_selected_session"].pvs.file_blob,
        contener
      );
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      const dataSign = new FormData();
      let userId = this.userData.id;
      let ChartTitle = this.$store.getters["Auth/get_attendance_list"].users_commissions;
      let UserTitle = ChartTitle.filter(function(value, index, arr) {
        return value.user.id == userId;
      }, userId);
      dataSign.append("session_id", this.id);
      dataSign.append("file_blob", data);
      // PROFILE SIGNING
      dataSign.append("is_rep", 0);
      dataSign.append("is_op", 0);
      dataSign.append("is_com", 1);
      // DATA TO SEND
      dataSign.append("name", this.userData.firstname);
      dataSign.append("surname", this.userData.lastname);
      dataSign.append("email", this.userData.email);
      dataSign.append("fonction", this.userData.profession);
      dataSign.append("quality", UserTitle[0].type.description);

      if (!isEmpty) {
        this.$store.dispatch("Auth/addSignature", dataSign);
      } else {
        //alert swal sign empty
        //console.log(this.userData)
      }
    },
    comCall() {
      const data = new FormData();
      data.append("commission", this.com.com);
      data.append("session", this.id);
      data.append("file", this.com.file[0]);
      this.$store.dispatch("Auth/comCall", data);
    },
    financialPropAdd() {
      const data = new FormData();
      data.append("document", this.$store.getters["Auth/get_selected_session"].session.document.id);
      data.append("selected_offer", this.fin.selected);
      data.append("start_time", this.fin.start_time);
      data.append("duration", 160);
      data.append("password", 1234);
      this.$store.dispatch("Auth/financialPropAdd", data);
    }
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
.vue-daterange-picker {
  width: 100%;
}
.mx-datepicker {
  width: 100%;
}
</style>
